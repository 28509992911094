{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/ProjectTemplate",
  "definitions": {
    "ProjectTemplate": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "version": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "description": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        },
        "modelUrl": {
          "type": "string"
        },
        "unlit": {
          "type": "boolean"
        },
        "navigation": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectNavItem"
          }
        },
        "materials": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectMaterial"
          }
        },
        "rooms": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectTemplateRoom"
          }
        },
        "fog": {
          "$ref": "#/definitions/ProjectFog"
        },
        "toneMapping": {
          "$ref": "#/definitions/ProjectToneMapping"
        },
        "triggers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectTrigger"
          }
        }
      },
      "required": [
        "id",
        "version",
        "name",
        "description",
        "thumbUrl",
        "modelUrl",
        "unlit",
        "navigation",
        "materials",
        "rooms"
      ],
      "additionalProperties": false
    },
    "ProjectNavItem": {
      "type": "object",
      "properties": {
        "target": {
          "type": "string"
        },
        "boundary": {
          "type": "string"
        },
        "roomId": {
          "type": "string"
        }
      },
      "required": ["target", "boundary", "roomId"],
      "additionalProperties": false
    },
    "ProjectMaterial": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        }
      },
      "required": ["id", "name", "thumbUrl"],
      "additionalProperties": false
    },
    "ProjectTemplateRoom": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "startPosition": {
          "$ref": "#/definitions/Vec3"
        },
        "startRotation": {
          "type": "number"
        },
        "floorMesh": {
          "type": "string"
        },
        "wallMesh": {
          "type": "string"
        },
        "ceilingMesh": {
          "type": "string"
        },
        "floorMaterials": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "wallMaterials": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "ceilingMaterials": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "ambientLight": {
          "$ref": "#/definitions/AmbientLight"
        },
        "directionalLight": {
          "$ref": "#/definitions/DirectionalLight"
        },
        "decorationSlots": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomSlot"
          }
        },
        "slots": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomSlot"
          }
        },
        "autoEnvMap": {
          "type": "object",
          "properties": {
            "position": {
              "$ref": "#/definitions/Vec3"
            },
            "hideObjects": {
              "type": "array",
              "items": {
                "type": "string"
              }
            }
          },
          "required": ["position"],
          "additionalProperties": false
        }
      },
      "required": [
        "id",
        "name",
        "startPosition",
        "startRotation",
        "floorMesh",
        "wallMesh",
        "ceilingMesh",
        "floorMaterials",
        "wallMaterials",
        "ceilingMaterials",
        "ambientLight",
        "directionalLight",
        "decorationSlots",
        "slots"
      ],
      "additionalProperties": false
    },
    "Vec3": {
      "type": "array",
      "items": {
        "type": "number"
      },
      "minItems": 3,
      "maxItems": 3
    },
    "AmbientLight": {
      "type": "object",
      "properties": {
        "color": {
          "type": "string"
        },
        "intensity": {
          "type": "number"
        }
      },
      "required": ["color", "intensity"],
      "additionalProperties": false
    },
    "DirectionalLight": {
      "type": "object",
      "properties": {
        "color": {
          "type": "string"
        },
        "intensity": {
          "type": "number"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        }
      },
      "required": ["color", "intensity", "position"],
      "additionalProperties": false
    },
    "RoomSlot": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        },
        "quaternion": {
          "$ref": "#/definitions/Vec4"
        },
        "size": {
          "$ref": "#/definitions/Vec3"
        }
      },
      "required": ["id", "name", "position", "quaternion", "size"],
      "additionalProperties": false
    },
    "Vec4": {
      "type": "array",
      "items": {
        "type": "number"
      },
      "minItems": 4,
      "maxItems": 4
    },
    "ProjectFog": {
      "anyOf": [
        {
          "$ref": "#/definitions/ProjectFogRange"
        },
        {
          "$ref": "#/definitions/ProjectFogDensity"
        }
      ]
    },
    "ProjectFogRange": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "range"
        },
        "color": {
          "type": "string"
        },
        "near": {
          "type": "number"
        },
        "far": {
          "type": "number"
        }
      },
      "required": ["type", "color", "near", "far"],
      "additionalProperties": false
    },
    "ProjectFogDensity": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "density"
        },
        "color": {
          "type": "string"
        },
        "density": {
          "type": "number"
        }
      },
      "required": ["type", "color", "density"],
      "additionalProperties": false
    },
    "ProjectToneMapping": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": [
            "NoToneMapping",
            "LinearToneMapping",
            "ReinhardToneMapping",
            "CineonToneMapping",
            "ACESFilmicToneMapping"
          ]
        },
        "exposure": {
          "type": "number"
        }
      },
      "required": ["type", "exposure"],
      "additionalProperties": false
    },
    "ProjectTrigger": {
      "type": "object",
      "properties": {
        "event": {
          "anyOf": [
            {
              "$ref": "#/definitions/ProjectTriggerInitEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerClickEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerProximityEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerRadiusEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerRoomChangeEvent"
            }
          ]
        },
        "action": {
          "anyOf": [
            {
              "$ref": "#/definitions/ProjectTriggerLinkAction"
            },
            {
              "$ref": "#/definitions/ProjectTriggerInstanceAction"
            },
            {
              "$ref": "#/definitions/ProjectTriggerAnimationAction"
            }
          ]
        }
      },
      "required": ["event", "action"],
      "additionalProperties": false
    },
    "ProjectTriggerInitEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "init"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "ProjectTriggerClickEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "click"
        },
        "object": {
          "type": "string"
        }
      },
      "required": ["object", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerProximityEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterProximity", "exitProximity"]
        },
        "object": {
          "type": "string"
        },
        "distance": {
          "type": "number"
        }
      },
      "required": ["distance", "object", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerRadiusEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterRadius", "exitRadius"]
        },
        "object": {
          "type": "string"
        },
        "radius": {
          "type": "number"
        },
        "ignoreAxis": {
          "type": "string",
          "enum": ["", "x", "y", "z"]
        }
      },
      "required": ["object", "radius", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerRoomChangeEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterRoom", "exitRoom"]
        },
        "roomId": {
          "type": "string"
        }
      },
      "required": ["roomId", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerLinkAction": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "link"
        },
        "href": {
          "type": "string"
        },
        "newWindow": {
          "type": "boolean"
        }
      },
      "required": ["href", "newWindow", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerInstanceAction": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "setRoomInstance"
        },
        "roomId": {
          "type": "string"
        },
        "instance": {
          "anyOf": [
            {
              "type": "number"
            },
            {
              "type": "string",
              "const": "next"
            },
            {
              "type": "string",
              "const": "previous"
            }
          ]
        }
      },
      "required": ["instance", "roomId", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerAnimationAction": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["playAnimation", "pauseAnimation", "stopAnimation", "toggleAnimation"]
        },
        "animationName": {
          "type": "string"
        },
        "loop": {
          "type": "boolean"
        }
      },
      "required": ["animationName", "type"],
      "additionalProperties": false
    }
  }
}
