{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/Project",
  "definitions": {
    "Project": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "createdAt": {
          "type": "string"
        },
        "templateId": {
          "type": "string"
        },
        "templateVersion": {
          "type": "string"
        },
        "active": {
          "type": "boolean"
        },
        "slug": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "modelUrl": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        },
        "logoUrl": {
          "type": "string"
        },
        "bgAudioUrl": {
          "type": "string"
        },
        "bgAudioAutoplay": {
          "type": "boolean"
        },
        "defaultLang": {
          "$ref": "#/definitions/ProjectLanguage"
        },
        "unlit": {
          "type": "boolean"
        },
        "startRoom": {
          "type": "string"
        },
        "startPosition": {
          "$ref": "#/definitions/Vec3"
        },
        "startRotation": {
          "type": "number"
        },
        "navigation": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectNavItem"
          }
        },
        "languages": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectLanguage"
          }
        },
        "materials": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectMaterial"
          }
        },
        "rooms": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Room"
          }
        },
        "theme": {
          "$ref": "#/definitions/ProjectTheme"
        },
        "files": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectFile"
          }
        },
        "data": {
          "type": "object",
          "properties": {
            "en": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "fi": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "sv": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "de": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "fr": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "es": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "it": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "ru": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "zh": {
              "$ref": "#/definitions/ProjectLanguageData"
            },
            "ja": {
              "$ref": "#/definitions/ProjectLanguageData"
            }
          },
          "additionalProperties": false
        },
        "fog": {
          "$ref": "#/definitions/ProjectFog"
        },
        "toneMapping": {
          "$ref": "#/definitions/ProjectToneMapping"
        },
        "triggers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ProjectTrigger"
          }
        },
        "hideHotspotButtons": {
          "type": "boolean"
        },
        "discoverable": {
          "type": "boolean"
        },
        "introBgUrl": {
          "type": "string"
        }
      },
      "required": [
        "id",
        "createdAt",
        "templateId",
        "templateVersion",
        "active",
        "slug",
        "name",
        "modelUrl",
        "thumbUrl",
        "logoUrl",
        "bgAudioUrl",
        "bgAudioAutoplay",
        "defaultLang",
        "unlit",
        "startRoom",
        "startPosition",
        "startRotation",
        "navigation",
        "languages",
        "materials",
        "rooms",
        "theme",
        "files",
        "data"
      ],
      "additionalProperties": false
    },
    "ProjectLanguage": {
      "type": "string",
      "enum": ["en", "fi", "sv", "de", "fr", "es", "it", "ru", "zh", "ja"]
    },
    "Vec3": {
      "type": "array",
      "items": {
        "type": "number"
      },
      "minItems": 3,
      "maxItems": 3
    },
    "ProjectNavItem": {
      "type": "object",
      "properties": {
        "target": {
          "type": "string"
        },
        "boundary": {
          "type": "string"
        },
        "roomId": {
          "type": "string"
        }
      },
      "required": ["target", "boundary", "roomId"],
      "additionalProperties": false
    },
    "ProjectMaterial": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        }
      },
      "required": ["id", "name", "thumbUrl"],
      "additionalProperties": false
    },
    "Room": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "directionalLight": {
          "$ref": "#/definitions/DirectionalLight"
        },
        "ambientLight": {
          "$ref": "#/definitions/AmbientLight"
        },
        "objects": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomObject"
          }
        },
        "startPosition": {
          "$ref": "#/definitions/Vec3"
        },
        "startRotation": {
          "type": "number"
        },
        "floorMesh": {
          "type": "string"
        },
        "wallMesh": {
          "type": "string"
        },
        "ceilingMesh": {
          "type": "string"
        },
        "floorMaterial": {
          "type": "string"
        },
        "wallMaterial": {
          "type": "string"
        },
        "ceilingMaterial": {
          "type": "string"
        },
        "floorMaterials": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "wallMaterials": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "ceilingMaterials": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "decorationSlots": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomSlot"
          }
        },
        "slots": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomSlot"
          }
        },
        "autoEnvMap": {
          "type": "object",
          "properties": {
            "position": {
              "$ref": "#/definitions/Vec3"
            },
            "hideObjects": {
              "type": "array",
              "items": {
                "type": "string"
              }
            }
          },
          "required": ["position", "hideObjects"],
          "additionalProperties": false
        },
        "loopInstances": {
          "type": "boolean"
        }
      },
      "required": [
        "id",
        "name",
        "directionalLight",
        "ambientLight",
        "objects",
        "startPosition",
        "startRotation",
        "floorMesh",
        "wallMesh",
        "ceilingMesh",
        "floorMaterial",
        "wallMaterial",
        "ceilingMaterial",
        "floorMaterials",
        "wallMaterials",
        "ceilingMaterials",
        "decorationSlots",
        "slots"
      ],
      "additionalProperties": false
    },
    "DirectionalLight": {
      "type": "object",
      "properties": {
        "color": {
          "type": "string"
        },
        "intensity": {
          "type": "number"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        }
      },
      "required": ["color", "intensity", "position"],
      "additionalProperties": false
    },
    "AmbientLight": {
      "type": "object",
      "properties": {
        "color": {
          "type": "string"
        },
        "intensity": {
          "type": "number"
        }
      },
      "required": ["color", "intensity"],
      "additionalProperties": false
    },
    "RoomObject": {
      "anyOf": [
        {
          "$ref": "#/definitions/RoomImageObject"
        },
        {
          "$ref": "#/definitions/RoomVideoObject"
        },
        {
          "$ref": "#/definitions/RoomModelObject"
        }
      ]
    },
    "RoomImageObject": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "const": "image"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        },
        "raycastable": {
          "type": "boolean"
        },
        "faceCamera": {
          "type": "boolean"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        },
        "quaternion": {
          "$ref": "#/definitions/Vec4"
        },
        "offsetPosition": {
          "$ref": "#/definitions/Vec3"
        },
        "offsetRotation": {
          "$ref": "#/definitions/Vec3"
        },
        "size": {
          "$ref": "#/definitions/Vec3"
        },
        "triggers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomObjectTrigger"
          }
        },
        "info": {
          "type": "object",
          "properties": {
            "enabled": {
              "type": "boolean"
            },
            "asset": {
              "anyOf": [
                {
                  "$ref": "#/definitions/RoomObjectInfoAsset"
                },
                {
                  "type": "null"
                }
              ]
            },
            "description": {
              "type": "string"
            },
            "iframeUrl": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "likeEnabled": {
              "type": "boolean"
            },
            "ctaEnabled": {
              "type": "boolean"
            },
            "ctaText": {
              "type": "string"
            },
            "ctaUrl": {
              "type": "string"
            }
          },
          "required": ["enabled", "asset", "description"],
          "additionalProperties": false
        },
        "slotId": {
          "type": "string"
        },
        "preventHotspot": {
          "type": "boolean"
        },
        "material": {
          "anyOf": [
            {
              "$ref": "#/definitions/RoomObjectBasicMaterial"
            },
            {
              "$ref": "#/definitions/RoomObjectStandardMaterial"
            }
          ]
        },
        "frame": {
          "type": "object",
          "properties": {
            "depth": {
              "type": "number"
            },
            "bezel": {
              "type": "number"
            },
            "color": {
              "type": "string"
            }
          },
          "required": ["depth", "bezel", "color"],
          "additionalProperties": false
        }
      },
      "required": [
        "faceCamera",
        "frame",
        "id",
        "info",
        "material",
        "offsetPosition",
        "offsetRotation",
        "position",
        "quaternion",
        "raycastable",
        "size",
        "slotId",
        "thumbUrl",
        "triggers",
        "type",
        "url"
      ],
      "additionalProperties": false
    },
    "Vec4": {
      "type": "array",
      "items": {
        "type": "number"
      },
      "minItems": 4,
      "maxItems": 4
    },
    "RoomObjectTrigger": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "event": {
          "$ref": "#/definitions/RoomObjectTriggerAnyEvent"
        },
        "actions": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomObjectTriggerAnyAction"
          }
        }
      },
      "required": ["id", "event", "actions"],
      "additionalProperties": false
    },
    "RoomObjectTriggerAnyEvent": {
      "anyOf": [
        {
          "$ref": "#/definitions/RoomObjectTriggerInitEvent"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerInfoEvent"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerClickEvent"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerProximityEvent"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerRadiusEvent"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerRoomChangeEvent"
        }
      ]
    },
    "RoomObjectTriggerInitEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "init"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerInfoEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["openInfo", "closeInfo"]
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerClickEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "click"
        },
        "object": {
          "type": "string"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerProximityEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterProximity", "exitProximity"]
        },
        "distance": {
          "type": "number"
        },
        "object": {
          "type": "string"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerRadiusEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterRadius", "exitRadius"]
        },
        "radius": {
          "type": "number"
        },
        "object": {
          "type": "string"
        },
        "ignoreAxis": {
          "type": "string",
          "enum": ["", "x", "y", "z"]
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerRoomChangeEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterRoom", "exitRoom"]
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerAnyAction": {
      "anyOf": [
        {
          "$ref": "#/definitions/RoomObjectTriggerAnimationAction"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerInstanceAction"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerSoundAction"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerVideoAction"
        },
        {
          "$ref": "#/definitions/RoomObjectTriggerLinkAction"
        }
      ]
    },
    "RoomObjectTriggerAnimationAction": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "enum": ["playAnimation", "pauseAnimation", "stopAnimation", "toggleAnimation"]
        },
        "animationName": {
          "type": "string"
        },
        "loop": {
          "type": "boolean"
        }
      },
      "required": ["animationName", "id", "type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerInstanceAction": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "const": "setRoomInstance"
        },
        "roomId": {
          "type": "string"
        },
        "instance": {
          "anyOf": [
            {
              "type": "number"
            },
            {
              "type": "string",
              "const": "next"
            },
            {
              "type": "string",
              "const": "previous"
            }
          ]
        }
      },
      "required": ["id", "instance", "roomId", "type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerSoundAction": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "enum": ["playSound", "pauseSound", "stopSound", "toggleSound"]
        },
        "url": {
          "type": "string"
        },
        "volume": {
          "type": "number"
        },
        "loop": {
          "type": "boolean"
        },
        "isolated": {
          "type": "boolean"
        },
        "positional": {
          "type": "boolean"
        },
        "distanceModel": {
          "type": "string",
          "enum": ["linear", "inverse", "exponential"]
        },
        "refDistance": {
          "type": "number"
        },
        "rolloffFactor": {
          "type": "number"
        },
        "maxDistance": {
          "type": "number"
        },
        "coneInnerAngle": {
          "type": "number"
        },
        "coneOuterAngle": {
          "type": "number"
        },
        "coneOuterGain": {
          "type": "number"
        }
      },
      "required": ["id", "type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerVideoAction": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "enum": ["playVideo", "pauseVideo", "stopVideo", "toggleVideo"]
        }
      },
      "required": ["id", "type"],
      "additionalProperties": false
    },
    "RoomObjectTriggerLinkAction": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "const": "link"
        },
        "href": {
          "type": "string"
        },
        "newWindow": {
          "type": "boolean"
        }
      },
      "required": ["href", "id", "newWindow", "type"],
      "additionalProperties": false
    },
    "RoomObjectInfoAsset": {
      "anyOf": [
        {
          "$ref": "#/definitions/RoomObjectInfoAssetImage"
        },
        {
          "$ref": "#/definitions/RoomObjectInfoAssetVideo"
        },
        {
          "$ref": "#/definitions/RoomObjectInfoAssetModel"
        }
      ]
    },
    "RoomObjectInfoAssetImage": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "image"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        }
      },
      "required": ["thumbUrl", "type", "url"],
      "additionalProperties": false
    },
    "RoomObjectInfoAssetVideo": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "video"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        }
      },
      "required": ["thumbUrl", "type", "url"],
      "additionalProperties": false
    },
    "RoomObjectInfoAssetModel": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "model"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        }
      },
      "required": ["thumbUrl", "type", "url"],
      "additionalProperties": false
    },
    "RoomObjectBasicMaterial": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "basic"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomObjectStandardMaterial": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "standard"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomVideoObject": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "const": "video"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        },
        "raycastable": {
          "type": "boolean"
        },
        "faceCamera": {
          "type": "boolean"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        },
        "quaternion": {
          "$ref": "#/definitions/Vec4"
        },
        "offsetPosition": {
          "$ref": "#/definitions/Vec3"
        },
        "offsetRotation": {
          "$ref": "#/definitions/Vec3"
        },
        "size": {
          "$ref": "#/definitions/Vec3"
        },
        "triggers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomObjectTrigger"
          }
        },
        "info": {
          "type": "object",
          "properties": {
            "enabled": {
              "type": "boolean"
            },
            "asset": {
              "anyOf": [
                {
                  "$ref": "#/definitions/RoomObjectInfoAsset"
                },
                {
                  "type": "null"
                }
              ]
            },
            "description": {
              "type": "string"
            },
            "iframeUrl": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "likeEnabled": {
              "type": "boolean"
            },
            "ctaEnabled": {
              "type": "boolean"
            },
            "ctaText": {
              "type": "string"
            },
            "ctaUrl": {
              "type": "string"
            }
          },
          "required": ["enabled", "asset", "description"],
          "additionalProperties": false
        },
        "slotId": {
          "type": "string"
        },
        "preventHotspot": {
          "type": "boolean"
        },
        "material": {
          "anyOf": [
            {
              "$ref": "#/definitions/RoomObjectBasicMaterial"
            },
            {
              "$ref": "#/definitions/RoomObjectStandardMaterial"
            },
            {
              "$ref": "#/definitions/RoomObjectChromaKeyMaterial"
            },
            {
              "$ref": "#/definitions/RoomObjectAlphaCutoutMaterial"
            }
          ]
        },
        "frame": {
          "type": "object",
          "properties": {
            "depth": {
              "type": "number"
            },
            "bezel": {
              "type": "number"
            },
            "color": {
              "type": "string"
            }
          },
          "required": ["depth", "bezel", "color"],
          "additionalProperties": false
        },
        "volume": {
          "type": "number"
        },
        "loop": {
          "type": "boolean"
        },
        "autoplay": {
          "type": "boolean"
        },
        "controls": {
          "type": "boolean"
        },
        "isolated": {
          "type": "boolean"
        }
      },
      "required": [
        "autoplay",
        "controls",
        "faceCamera",
        "frame",
        "id",
        "info",
        "isolated",
        "loop",
        "material",
        "offsetPosition",
        "offsetRotation",
        "position",
        "quaternion",
        "raycastable",
        "size",
        "slotId",
        "thumbUrl",
        "triggers",
        "type",
        "url",
        "volume"
      ],
      "additionalProperties": false
    },
    "RoomObjectChromaKeyMaterial": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "chromakey"
        },
        "replaceColor": {
          "type": "string"
        }
      },
      "required": ["type", "replaceColor"],
      "additionalProperties": false
    },
    "RoomObjectAlphaCutoutMaterial": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "alphacutout"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "RoomModelObject": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "const": "model"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        },
        "raycastable": {
          "type": "boolean"
        },
        "faceCamera": {
          "type": "boolean"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        },
        "quaternion": {
          "$ref": "#/definitions/Vec4"
        },
        "offsetPosition": {
          "$ref": "#/definitions/Vec3"
        },
        "offsetRotation": {
          "$ref": "#/definitions/Vec3"
        },
        "size": {
          "$ref": "#/definitions/Vec3"
        },
        "triggers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomObjectTrigger"
          }
        },
        "info": {
          "type": "object",
          "properties": {
            "enabled": {
              "type": "boolean"
            },
            "asset": {
              "anyOf": [
                {
                  "$ref": "#/definitions/RoomObjectInfoAsset"
                },
                {
                  "type": "null"
                }
              ]
            },
            "description": {
              "type": "string"
            },
            "iframeUrl": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "likeEnabled": {
              "type": "boolean"
            },
            "ctaEnabled": {
              "type": "boolean"
            },
            "ctaText": {
              "type": "string"
            },
            "ctaUrl": {
              "type": "string"
            }
          },
          "required": ["enabled", "asset", "description"],
          "additionalProperties": false
        },
        "slotId": {
          "type": "string"
        },
        "preventHotspot": {
          "type": "boolean"
        },
        "environmentMap": {
          "type": "string",
          "enum": ["none", "auto", "equirectangular"]
        },
        "environmentMapIntensity": {
          "type": "number"
        },
        "environmentMapUrl": {
          "type": "string"
        }
      },
      "required": [
        "environmentMap",
        "environmentMapIntensity",
        "environmentMapUrl",
        "faceCamera",
        "id",
        "info",
        "offsetPosition",
        "offsetRotation",
        "position",
        "quaternion",
        "raycastable",
        "size",
        "slotId",
        "thumbUrl",
        "triggers",
        "type",
        "url"
      ],
      "additionalProperties": false
    },
    "RoomSlot": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "position": {
          "$ref": "#/definitions/Vec3"
        },
        "quaternion": {
          "$ref": "#/definitions/Vec4"
        },
        "size": {
          "$ref": "#/definitions/Vec3"
        }
      },
      "required": ["id", "name", "position", "quaternion", "size"],
      "additionalProperties": false
    },
    "ProjectTheme": {
      "type": "object",
      "properties": {
        "textColor": {
          "type": "string"
        },
        "headingColor": {
          "type": "string"
        },
        "bgColor": {
          "type": "string"
        },
        "actionColor": {
          "type": "string"
        },
        "actionActiveColor": {
          "type": "string"
        },
        "markerColor": {
          "type": "string"
        },
        "fontUrl": {
          "type": "string"
        },
        "fontFamily": {
          "type": "string"
        },
        "fontWeightNormal": {
          "type": "number"
        },
        "fontWeightBold": {
          "type": "number"
        }
      },
      "required": [
        "textColor",
        "headingColor",
        "bgColor",
        "actionColor",
        "actionActiveColor",
        "markerColor",
        "fontUrl",
        "fontFamily",
        "fontWeightNormal",
        "fontWeightBold"
      ],
      "additionalProperties": false
    },
    "ProjectFile": {
      "type": "object",
      "properties": {
        "hash": {
          "type": "string"
        },
        "url": {
          "type": "string"
        },
        "thumbUrl": {
          "type": "string"
        },
        "refCount": {
          "type": "number"
        }
      },
      "required": ["hash", "url", "thumbUrl", "refCount"],
      "additionalProperties": false
    },
    "ProjectLanguageData": {
      "type": "object",
      "properties": {
        "langCode": {
          "$ref": "#/definitions/ProjectLanguage"
        },
        "welcomeText": {
          "type": "string"
        },
        "infoText": {
          "type": "string"
        },
        "metaTitle": {
          "type": "string"
        },
        "metaDesc": {
          "type": "string"
        },
        "metaImageUrl": {
          "type": "string"
        },
        "roomInstances": {
          "type": "object",
          "additionalProperties": {
            "type": "array",
            "items": {
              "$ref": "#/definitions/RoomInstance"
            }
          }
        }
      },
      "required": [
        "langCode",
        "welcomeText",
        "infoText",
        "metaTitle",
        "metaDesc",
        "metaImageUrl",
        "roomInstances"
      ],
      "additionalProperties": false
    },
    "RoomInstance": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        },
        "objects": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/RoomObject"
          }
        }
      },
      "required": ["id", "objects"],
      "additionalProperties": false
    },
    "ProjectFog": {
      "anyOf": [
        {
          "$ref": "#/definitions/ProjectFogRange"
        },
        {
          "$ref": "#/definitions/ProjectFogDensity"
        }
      ]
    },
    "ProjectFogRange": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "range"
        },
        "color": {
          "type": "string"
        },
        "near": {
          "type": "number"
        },
        "far": {
          "type": "number"
        }
      },
      "required": ["type", "color", "near", "far"],
      "additionalProperties": false
    },
    "ProjectFogDensity": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "density"
        },
        "color": {
          "type": "string"
        },
        "density": {
          "type": "number"
        }
      },
      "required": ["type", "color", "density"],
      "additionalProperties": false
    },
    "ProjectToneMapping": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": [
            "NoToneMapping",
            "LinearToneMapping",
            "ReinhardToneMapping",
            "CineonToneMapping",
            "ACESFilmicToneMapping"
          ]
        },
        "exposure": {
          "type": "number"
        }
      },
      "required": ["type", "exposure"],
      "additionalProperties": false
    },
    "ProjectTrigger": {
      "type": "object",
      "properties": {
        "event": {
          "anyOf": [
            {
              "$ref": "#/definitions/ProjectTriggerInitEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerClickEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerProximityEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerRadiusEvent"
            },
            {
              "$ref": "#/definitions/ProjectTriggerRoomChangeEvent"
            }
          ]
        },
        "action": {
          "anyOf": [
            {
              "$ref": "#/definitions/ProjectTriggerLinkAction"
            },
            {
              "$ref": "#/definitions/ProjectTriggerInstanceAction"
            },
            {
              "$ref": "#/definitions/ProjectTriggerAnimationAction"
            }
          ]
        }
      },
      "required": ["event", "action"],
      "additionalProperties": false
    },
    "ProjectTriggerInitEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "init"
        }
      },
      "required": ["type"],
      "additionalProperties": false
    },
    "ProjectTriggerClickEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "click"
        },
        "object": {
          "type": "string"
        }
      },
      "required": ["object", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerProximityEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterProximity", "exitProximity"]
        },
        "object": {
          "type": "string"
        },
        "distance": {
          "type": "number"
        }
      },
      "required": ["distance", "object", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerRadiusEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterRadius", "exitRadius"]
        },
        "object": {
          "type": "string"
        },
        "radius": {
          "type": "number"
        },
        "ignoreAxis": {
          "type": "string",
          "enum": ["", "x", "y", "z"]
        }
      },
      "required": ["object", "radius", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerRoomChangeEvent": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["enterRoom", "exitRoom"]
        },
        "roomId": {
          "type": "string"
        }
      },
      "required": ["roomId", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerLinkAction": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "link"
        },
        "href": {
          "type": "string"
        },
        "newWindow": {
          "type": "boolean"
        }
      },
      "required": ["href", "newWindow", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerInstanceAction": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "const": "setRoomInstance"
        },
        "roomId": {
          "type": "string"
        },
        "instance": {
          "anyOf": [
            {
              "type": "number"
            },
            {
              "type": "string",
              "const": "next"
            },
            {
              "type": "string",
              "const": "previous"
            }
          ]
        }
      },
      "required": ["instance", "roomId", "type"],
      "additionalProperties": false
    },
    "ProjectTriggerAnimationAction": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["playAnimation", "pauseAnimation", "stopAnimation", "toggleAnimation"]
        },
        "animationName": {
          "type": "string"
        },
        "loop": {
          "type": "boolean"
        }
      },
      "required": ["animationName", "type"],
      "additionalProperties": false
    }
  }
}
